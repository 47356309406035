const getViewerContextGraphqlQuery = () => {
  const operationName = 'VitrineContext'

  const query = `
  query VitrineContext {
    viewer {
      user {
        isFirstBuy
      }
    }
  }
`

  return {
    operationName,
    query,
  }
}

export const checkUserIsFirstBuy = async (authToken: string | null) => {
  if (!authToken) {
    return null
  }

  const graphqlQuery = getViewerContextGraphqlQuery()

  const endpoint = process.env.NEXT_PUBLIC_PALANTIR_GRAPHQL_URL as string

  const headers = {
    'content-type': 'application/json',
    authorization: authToken.includes('Bearer')
      ? authToken
      : `Bearer ${authToken}`,
  }

  return fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(graphqlQuery),
    headers,
  })
    .then(async res => {
      if (res.status !== 200) {
        throw new Error('Error fetching user context')
      }

      const data = await res.json()

      return data?.data?.viewer?.user?.isFirstBuy
    })
    .catch(err => console.error(err))
}
