import React, { useMemo } from 'react'

import { Menu } from '../../../types'
import { MenuLoading } from './components/loading'
import { useMenuNavigation } from './hook'
import { MenuDesktopUI } from './ui'
import { adaptDesktopMenu } from './logic'

export const MenuDesktop = ({
  menu,
  isLoading = false,
}: {
  isLoading?: boolean
  menu: Menu[]
}) => {
  const { onClickMenu } = useMenuNavigation()
  const menuItems = useMemo(() => adaptDesktopMenu(menu), [menu])

  if (isLoading) {
    return <MenuLoading />
  }
  return <MenuDesktopUI handleClickMenu={onClickMenu} menuItems={menuItems} />
}
