import { useEffect, useState } from 'react'

import { useLojinhaContext } from '@lojinha/context'
import { MENU_DATA } from '../menu'

export const useMenu = () => {
  const { isAuthenticated, isContextLoaded } = useLojinhaContext()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const isLoaded = isAuthenticated && isContextLoaded
    if (!isAuthenticated || isLoaded) setIsLoading(false)
  }, [isAuthenticated, isContextLoaded])

  return {
    menuData: MENU_DATA,
    isAuthenticated,
    isLoading,
  }
}
