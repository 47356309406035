import { beholder } from '@lojinha/beholder'
import { Router } from '@lojinha/vitrine/router'
import { Menu } from '../../../../types'

export const useMenuNavigation = () => {
  const onClickMenu = (currentMenu: Menu) => {
    beholder.shot('structuredEvent', {
      category: 'navigation',
      action: 'access_category',
      label: currentMenu.title?.toLowerCase(),
      property: currentMenu.route,
    })

    if (currentMenu.route) {
      Router.push(currentMenu.route)
    }
  }

  return {
    onClickMenu,
  }
}
