import { useLojinhaContext } from '@lojinha/context'
import { LocalizationModalType } from '@lojinha/context/src/types'
import { isPageSlug } from '@lojinha/helpers'
import {
  GetPageContentQuery,
  GetPageContentQueryVariables,
  IcmsUserProfiles,
  useQuery,
} from '@lojinha/palantir'
import { t } from '../../../dictionary'
import {
  DisplayComponent,
  PageComponent,
  PageContentProps,
} from '../page-content.types'
import { PAGE_QUERY } from './queries'

export const usePageContent = ({
  pageUrl = '',
}: {
  pageUrl: string | string[]
}): PageContentProps => {
  const slug = typeof pageUrl === 'object' ? pageUrl.join('/') : pageUrl
  const isHome = slug === 'nosso-cardapio' || slug === ''
  const limit = isHome ? 7 : undefined

  const {
    address: fullAddress,
    centerId: distributionCenterId,
    isFirstBuy = true,
    setLocalizationModal,
  } = useLojinhaContext()

  const pageQueryResult = useQuery<
    GetPageContentQuery,
    GetPageContentQueryVariables
  >(PAGE_QUERY, {
    variables: {
      slug,
      limit,
      userProfile: isFirstBuy
        ? IcmsUserProfiles.FirstBuy
        : IcmsUserProfiles.Rebuy,
      distributionCenterId: distributionCenterId ?? '',
    },
    skip: !isPageSlug(slug),
  })

  const onClickWillDeliver = () =>
    setLocalizationModal({ openedModal: LocalizationModalType.Address })

  const pageComponents = pageQueryResult.data?.page?.components.nodes
  const pageHasEmptyComponentsOrDisplays = !!pageComponents?.reduce(
    (isEmpty: boolean, component: PageComponent) => {
      if (component.__typename === 'CMSContainer') {
        return false
      }
      if (component.__typename !== 'Display') {
        return isEmpty
      }

      const displayComponent = component as DisplayComponent

      return isEmpty && !displayComponent.itemsOptimized.length
    },
    true
  )
  const showEmptyState = !isPageSlug(slug) || pageHasEmptyComponentsOrDisplays

  return {
    page: pageQueryResult.data?.page,
    loading: pageQueryResult.loading,
    fullPath: slug,
    showEmptyState,
    onClickWillDeliver,
    address: fullAddress && t.singleAddress(fullAddress),
    isHome,
  }
}
