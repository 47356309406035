import { ExperimentItem, Variants_Enum } from './types'

export const experiments: readonly ExperimentItem[] = [
  {
    id: 'zku_pricing_20250122',
    description: 'Teste A/B de precificação de SKUs',
    setInitialValueInHook: false,
    setInitialValueInSSR: false,
    variants: [
      {
        id: 'control',
        value: Variants_Enum.A,
        weight: 80,
      },
      {
        id: 'variant',
        value: Variants_Enum.B,
        weight: 20,
      },
    ],
  },
]
