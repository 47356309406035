import React from 'react'

import { Menu } from '../../../types'

export function adaptDesktopMenu(menu: Menu[]): Menu[] {
  return menu.map<Menu>(item => {
    const subItems = item.children ?? []
    return {
      ...item,
      route: item.route,
      reference: React.createRef(),
      children: subItems.map(child => ({
        ...child,
        reference: React.createRef(),
      })),
    }
  })
}
